import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface EventState {
  mode: string;
  sequence: number;
}

const initialState: EventState = {
  mode: 'add',
  sequence: 0,
};

const eventSlice = createSlice({
  name: "event",
  initialState,
  reducers: {
    setEventMode: (state, action: PayloadAction<string>) => {
      state.mode = action.payload;
    },
    setEventSequence: (state, action: PayloadAction<number>) => {
      state.sequence = action.payload;
    },
  },
});

export const { setEventMode, setEventSequence } = eventSlice.actions;
export default eventSlice.reducer;