import Box from "@mui/material/Box/Box";

export const TeamTaskIcon = () => (
  <Box
    component="img"
    src={'./icons/Task01.png'}
    sx={{ width: 14, height: 14 }}
  />
);

export const MyTaskIcon = () => (
  <Box
    component="img"
    src={'./icons/Task02.png'}
    sx={{ width: 14, height: 14 }}
  />
);

export const RadioButtonUncheckedIcon = () => (
  <Box
    component="img"
    src={'./icons/RadioButtonUncheckedIcon.png'}
    sx={{ width: 24, height: 24 }}
  />
);

export const RadioButtonCheckedIcon = () => (
  <Box
    component="img"
    src={'./icons/RadioButtonCheckedIcon.png'}
    sx={{ width: 24, height: 24 }}
  />
);