import './App.css';
import theme from './theme';
import { useIsAuthenticated } from "@azure/msal-react";
import { CheckUserRoles } from "./utils";
import SignIn from "./login";

import 'dayjs/locale/ko';
import ThemeProvider from '@mui/material/styles/ThemeProvider';
import CssBaseline from '@mui/material/CssBaseline';
import Box from '@mui/material/Box/Box';
import Header from './components/Header';

/* Sub Components */
import Slide from '@mui/material/Slide/Slide';
import Grid from '@mui/material/Grid/Grid';
import TaskList from './components/TaskList';
import StoreList from './components/StoreList';
import Calendar from './components/Calendar';
import Stack from '@mui/material/Stack/Stack';
import Button from "@mui/material/Button/Button";
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Typography from '@mui/material/Typography/Typography';
import { useEffect, useState } from 'react';
import AccordionPanel from './components/AccordionPanel';
// import Task from './components/TaskPanel';
import StorefrontIcon from '@mui/icons-material/Storefront';
import { fetchOPTStatus, OPTStatusType, submitStoreTasks } from './api/taskRequest';
import { useQuery } from 'react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from './reducer/dateRange';
import { setSelectedStatus } from './reducer/selectedList';
import { setEventSequence } from './reducer/eventStatus';

function getMondayAndSunday(date: Date) {
  const currentDate = new Date(date);
  const currentDay = currentDate.getDay();

  const differenceToMonday = (currentDay + 6) % 7;
  const monday = new Date(currentDate);
  monday.setDate(currentDate.getDate() - differenceToMonday);

  const sunday = new Date(monday);
  sunday.setDate(monday.getDate() + 6);

  return {
    monday: monday,
    sunday: sunday
  };
}

function formatStartDate(date: Date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());

  return `${year}년 ${month}월 ${day}일`;
}

function formatEndDate(date: Date) {
  const month = String(date.getMonth() + 1);
  const day = String(date.getDate());

  return `${month}월 ${day}일`;
}

function formatDate(type: string, date: Date) {
  if(type === 'dateRange.target_date'){
    return date.toString();
  }else{
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }
}

function addDays(date: Date, days: number) {
  const result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}

export default function App() {
  const isAuthenticated = useIsAuthenticated();
  const userInfo = useSelector((state: any) => state.user);
  const eventStatus = useSelector((state: any) => state.event);
  const selectedList = useSelector((state: any) => state.selectedList);

  if (userInfo.role === null)
  CheckUserRoles(sessionStorage.getItem("accessToken"));

  const tabIndex = useSelector((state: any) => state.tab);
  const funcBar = useSelector((state: any) => state.funcBar);
  const workFilter = useSelector((state: any) => state.workFilter);
  const dateRange = useSelector((state: any) => state.dateRange);

  const [expanded, setExpanded] = useState<string | false>('panel1');
  const [baseData, setBaseData] = useState<Date>(new Date());

  const dispatch = useDispatch();

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, newExpanded: boolean) => {
      setExpanded(newExpanded ? panel : false);
      dispatch(setSelectedStatus(panel));
    };

  const dates = getMondayAndSunday(baseData);

  let startDate = formatStartDate(dates.monday);
  let endDate = formatEndDate(dates.sunday);

  useEffect(() => {
    dispatch(setSelectedStatus("panel1"));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    console.log("## dates ##", formatDate('dates', dates.monday));
    dispatch(setDateRange({start_date: formatDate('start_date', dates.monday), end_date: formatDate('end_date', dates.sunday)}));
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [baseData, tabIndex]);

  const { data } = useQuery<OPTStatusType, Error>({
    queryKey: ["OPTStatus", dateRange.start_date, dateRange.end_date, eventStatus.sequence],
    queryFn: () => fetchOPTStatus(dateRange.start_date, dateRange.end_date),
    initialData: {taskList: [], storeStatus: []}, // 초기 데이터 설정
  });

  function onSendClick(): void {
    const selectedTaskIds = selectedList.taskId;

    console.log('Selected Task IDs:', selectedTaskIds);
    submitStoreTasks(selectedTaskIds);
    dispatch(setEventSequence(eventStatus.sequence + 1));
    alert("[TBU] 점포 일괄 전송 완료");
  }
  
  function onCompleteClick(): void {
    alert("[TBD] 완료 처리");
    dispatch(setEventSequence(eventStatus.sequence + 1));
  }
  
  function onDeleteClick(): void {
    alert("[TBD] 삭제 처리");
    dispatch(setEventSequence(eventStatus.sequence + 1));
  }

  useEffect(() => {
    const preventBack = () => {
      window.history.pushState(null, '', window.location.href);
    };

    const handlePopState = () => {
      preventBack();
    };

    window.history.pushState(null, '', window.location.href);
    window.addEventListener('popstate', handlePopState);

    return () => {
      window.removeEventListener('popstate', handlePopState);
    };
  }, []);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {!isAuthenticated || userInfo.role === null ? (
        <Box sx={{ display: "flex", minHeight: "100dvh", minWidth: "100dvw", justifyContent: "center" }}>
          <SignIn />
        </Box>
      ) : (
      <Box sx={{
        height: '100dvh',
        display: 'flex',
        flexDirection: 'column',
      }}>
        <Header startDate={formatDate('dates.monday', dates.monday)} endDate={formatDate('dates.sunday', dates.sunday)} targetDate={dateRange.target_date ? formatDate('dateRange.target_date', dateRange.target_date) : null}/>
        <Box sx={{ flex: '1 1 auto', display: 'flex', flexDirection: 'column', overflow: 'hidden', }}>
          <Slide direction="right" in={tabIndex.selectedIndex === 0} appear={false} mountOnEnter unmountOnExit>
            <Grid container sx={{ maxHeight: '100%', alignContent: 'flex-start' }}>
              <Stack direction={'row'} spacing={2} sx={{ py: 2, px: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                <Button variant="outlined" color="info" size="small" className='btn-navigation' onClick={() => setBaseData(addDays(baseData, -7))}>
                  <NavigateBeforeIcon className='btn-navigation-icon'/>
                </Button>
                <Typography sx={{ fontWeight: 700, color: '#424242', fontSize: '15px' }}>{startDate + ' ~ ' + endDate}</Typography>
                <Button variant="outlined" color="info" size="small" className='btn-navigation' onClick={() => setBaseData(addDays(baseData, 7))}>
                  <NavigateNextIcon className='btn-navigation-icon'/>
                  </Button>
              </Stack>
              <Stack direction={'column'} sx={{ width: '100%' }} >
                <AccordionPanel expanded={expanded === 'panel1'} onChange={handleChange('panel1')} title="할 일" count={data?.taskList?.filter(data => (data.taskStatus === "inprogress") && (workFilter.selectedWorkStatus === "all" ? true : data.taskType === workFilter.selectedWorkStatus)).length || 0} location="tasks">
                  <TaskList type='inprogress' taskList={data?.taskList || []}/>
                </AccordionPanel>
                <AccordionPanel expanded={expanded === 'panel2'} onChange={handleChange('panel2')} title="완료" count={data?.taskList?.filter(data => (data.taskStatus === "complete") && (workFilter.selectedWorkStatus === "all" ? true : data.taskType === workFilter.selectedWorkStatus)).length || 0} location="tasks">
                  <TaskList type='complete' taskList={data?.taskList || []}/>
                </AccordionPanel>
                <AccordionPanel expanded={expanded === 'panel3'} onChange={handleChange('panel3')} title="점포 리스트" count={data?.storeStatus?.length || 0} location="tasks">
                  <StoreList storeList={data?.storeStatus || []}/>
                </AccordionPanel>
              </Stack>
            </Grid>
          </Slide>
          <Slide direction="left" in={tabIndex.selectedIndex !== 0} appear={false} mountOnEnter unmountOnExit>
            {/* <Grid container sx={{ height: '100%', flexDirection: 'column', bgcolor: '#FFF' }}> */}
            <Grid container sx={{ maxHeight: '100%', alignContent: 'flex-start' }}>
              <Calendar />
            </Grid>
          </Slide>
        </Box>
        <Box display={funcBar.isChecked ? 'flex' : 'none'}
          sx={{ 
            zIndex: 1000000,
            position: 'absolute', bottom: '27px', left: 0, right: 0, width: 'calc(100% - 32px)', bgcolor: '#413F3F', px:'27px', margin: 2, height: '55px', justifyContent: 'space-between', borderRadius: '12px', boxShadow: '0 5px 20px rgba(0, 0, 0, 0.5);'
          }}>
          <Button variant='text' color='warning' disabled={selectedList.status !== "panel1"} startIcon={<StorefrontIcon />} sx={{fontSize: '16px', fontWeight: 300 }} onClick={() => onSendClick()}>보내기</Button>
          <Button variant='text' color='warning' startIcon={<StorefrontIcon />} sx={{fontSize: '16px', fontWeight: 300 }} onClick={() => onCompleteClick()}>완료</Button>
          <Button variant='text' color='warning' startIcon={<StorefrontIcon />} sx={{fontSize: '16px', fontWeight: 300 }} onClick={() => onDeleteClick()}>삭제</Button>
        </Box>
      </Box>)}
    </ThemeProvider>
  );
}