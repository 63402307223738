import { forwardRef, useState } from "react";

import Box from "@mui/material/Box/Box";
import Stack from "@mui/material/Stack/Stack";
import TextField from "@mui/material/TextField/TextField";
import Typography from "@mui/material/Typography/Typography";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider/LocalizationProvider";
import { MobileDatePicker } from "@mui/x-date-pickers/MobileDatePicker/MobileDatePicker";

import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { MobileTimePicker } from "@mui/x-date-pickers/MobileTimePicker/MobileTimePicker";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup/ToggleButtonGroup";
import ToggleButton from "@mui/material/ToggleButton/ToggleButton";

import { TeamTaskIcon, MyTaskIcon } from "./Icons";
import Button from "@mui/material/Button/Button";
import RadioGroup from "@mui/material/RadioGroup/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel/FormControlLabel";
import Radio from "@mui/material/Radio/Radio";
import { setWorkStatus } from "../reducer/workFilter";
import { useDispatch, useSelector } from "react-redux";

const style = {
  display: "flex",
  flexDirection: "column",
  bgcolor: "background.paper",
  boxShadow: 24,
  borderRadius: "20px 20px 0 0",
  p: 2,
  width: "100%",
  height: "220px",
  transition: "height 0.3s ease",
  overflow: "hidden",
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
};

const TaskFilter = forwardRef((props, ref) => {
  const workFilter = useSelector((state: any) => state.workFilter);
  const dispatch = useDispatch();
  const [alignment, setAlignment] = useState("left");

  const handleAlignment = (
    event: React.MouseEvent<HTMLElement>,
    newAlignment: string | null
  ) => {
    if (newAlignment !== null) {
      setAlignment(newAlignment);
    }
  };

  const handleTaskFilterChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const value = (event.target as HTMLInputElement).value as "all" | "team" | "private";
    dispatch(setWorkStatus(value));
  };

  return (
    <Box sx={style} alignContent={"center"} alignItems={"flex-start"}>
      <RadioGroup
        defaultValue={workFilter.selectedWorkStatus}
        name="radio-buttons-group"
        onChange={handleTaskFilterChange}
      >
        <FormControlLabel value="all" control={<Radio />} label="전체 보기"/>
        <FormControlLabel value="team" control={<Radio />} label={
          <Stack spacing={1} direction={"row"} sx={{ width: "100%", alignItems: 'center' }} >
            <TeamTaskIcon />
            <Typography variant="body1" sx={{ color: "#000", mt: 1, mb: 2 }}>팀 할 일 </Typography>
          </ Stack>} />
        <FormControlLabel value="private" control={<Radio />} label={
          <Stack spacing={1} direction={"row"} sx={{ width: "100%", alignItems: 'center' }} >
            <MyTaskIcon />
            <Typography variant="body1" sx={{ color: "#000", mt: 1, mb: 2 }}>내 할 일 </Typography>
          </ Stack>} />
      </RadioGroup>
    </Box>
  );
});

export default TaskFilter;