import { jwtDecode } from "jwt-decode";
import { setUserInfo } from './reducer/user';
import { useDispatch } from 'react-redux';
import { checkAuthApi } from "./api/auth";
import dayjs, { Dayjs } from 'dayjs';

declare module 'jwt-decode' {
	export interface JwtPayload {
			upn: string;
			name: string;
			given_name: string;
			oid: string;
    }
}

const roleGroups: { [key: string]: string[] } = {
  'reader': [
    '2088e2dd-0804-4dec-9b90-49fa8ff212cb' /* 교육서비스팀 */
    // , 'ad84f5d0-8d2e-4710-8c2c-3faa22ea38e9' /* DT기획팀 */
  ],
};
const roleAccounts: { [key: string]: string[] } = {
  'admin': ['creator7@gsretail.com' /* 김종서 부문장 */
    , 'wonschoi@gsretail.com' /* 최원식T DT기획팀 */
    , 'hyoslee@gsretail.com' /* 이효상M DT기획팀 */
    , 'kambba@gsretail.com'  /* 이재경M DT기획팀 */
    , 'yjinee@gsretail.com' /* 김유진M DT기획팀 */
    , 'shin804@gsretail.com' /* 신동훈T 교육서비스팀 */
  ],
//   'reader': ['jaehyeok.lee@gsretail.com'  /* 이재혁M 점포경영재구축TFT */
//     , 'wowkwk@gsretail.com' /* 김원경M 점포경영재구축TFT */
//   ],
};

export function openSidebar() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--SideNavigation-slideIn', '1');
    }
  }
  
  export function closeSidebar() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.removeProperty('--SideNavigation-slideIn');
      document.body.style.removeProperty('overflow');
    }
  }
  
  export function toggleSidebar() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
      .getComputedStyle(document.documentElement)
      .getPropertyValue('--SideNavigation-slideIn');
      if (slideIn) {
        closeSidebar();
      } else {
        openSidebar();
      }
    }
  }
  
  export function openMessagesPane() {
    if (typeof window !== 'undefined') {
      document.body.style.overflow = 'hidden';
      document.documentElement.style.setProperty('--MessagesPane-slideIn', '1');
    }
  }
  
  export function closeMessagesPane() {
    if (typeof window !== 'undefined') {
      document.documentElement.style.removeProperty('--MessagesPane-slideIn');
      document.body.style.removeProperty('overflow');
    }
  }
  
  export function toggleMessagesPane() {
    if (typeof window !== 'undefined' && typeof document !== 'undefined') {
      const slideIn = window
        .getComputedStyle(document.documentElement)
        .getPropertyValue('--MessagesPane-slideIn');
        if (slideIn) {
          closeMessagesPane();
        } else {
        openMessagesPane();
      }
    }
  }

  export function formatDateTaskListHeader(date: any) {
    const selectedDate = dayjs(date)
    const day = String(selectedDate.date()).padStart(2, '0');
    const weekday = selectedDate.locale('ko').format('dddd');
    return `${day}일 ${weekday}`;
  }

  export function formatDate(type: string, date: Dayjs) {
    const year = date.toDate().getFullYear();
    const month = String(date.toDate().getMonth() + 1).padStart(2, '0');
    const day = String(date.toDate().getDate()).padStart(2, '0');
    
    return `${year}-${month}-${day}`;
  }
  
  export function formatDateControlTitle(date: Dayjs) {
    const year = date.year();
    const month = String(date.month() + 1).padStart(2, '0');
    
    return `${year}년 ${month}월`;
  }
  
  
  const callMsGraph = async (accessToken: string, groupId: string) => {
    try {
      const response = await checkAuthApi(groupId);
      return response?.status === 200;
    } catch (error) {
      // console.info('Group Auth check fail - 1');
      return false;
    }
  };
  
  const findUserRoleByGroup = async (accessToken: string): Promise<string | null> => {
    for (const [role, groupIds] of Object.entries(roleGroups)) {
      for (const groupId of groupIds) {
            try {
              const isMember = await callMsGraph(accessToken, groupId);
              if (isMember) return role;
            } catch {
              // console.info('Group Auth check fail - 2');
            }
        }
    }
    return null;
  };
  
  const findUserRoleByEmail = (email: string | null): string | null => {
    if (email === null) return null;
  
    for (const [role, accounts] of Object.entries(roleAccounts)) {
        if (accounts.includes(email)) return role;
      }
    return null;
  };
  
  const checkUserAccount = (token: string) => {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  };
  
  export const CheckUserRoles = async (token: string | null) => {
    const dispatch = useDispatch();

    if (token === null) return;

    const tokenInfo = checkUserAccount(token);

    const roleByGroup = await findUserRoleByGroup(token);
    const roleByAccount = findUserRoleByEmail(tokenInfo.upn);
    
    if (roleByAccount) {
      dispatch(setUserInfo({
        email: tokenInfo.upn,
        name: tokenInfo.name,
        username: tokenInfo.given_name,
        uid: tokenInfo.oid,
        role: roleByAccount,
      }));
    } else if(roleByGroup) {
      dispatch(setUserInfo({
        email: tokenInfo.upn,
        name: tokenInfo.name,
        username: tokenInfo.given_name,
        uid: tokenInfo.oid,
        role: roleByGroup,
      }));
    } else {
      console.log('No role found');
    }
  }