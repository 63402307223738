import axios from "axios";
import { useMutation } from "react-query";
import store from "../store/store";
import { StoreSharp } from "@mui/icons-material";

interface BizplInfo {
  bizplCd: string;
  bizplNm: string;
  assignedTaskStatus: string;
}

interface Task {
  taskId: string;
  parentTaskId: string | null;
  title: string;
  taskType: "team" | "private";
  taskStatus: string;
  assignmentStatus: string;
  targetDate: string;
  createdBy: string;
  bizplInfo: BizplInfo[];
  checked: boolean;
  assignedTaskStatus?: string;
}

interface storeStatus {
  bizplNm: string;
  bizplCd: string;
  taskStatus: string;
}

interface TaskDataType {
  taskType: string;
  targetDate: string;
  taskList: string[];
}

const host = process.env.NODE_ENV === 'development' ? 'http://localhost:8080': 'https://gsr-dt-api-p003.azurewebsites.net';

export type OPTStatusType = {
  taskList: Task[];
  storeStatus: storeStatus[];
};

export type TaskListType = Task[];
export type StoreListType = storeStatus[];
export type StoreInfoType = storeStatus;

export async function fetchOPTStatus(
    startDate: string,
    endDate: string
): Promise<OPTStatusType> {
  const response = await fetch(`${host}/opt/status`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({ startDate, endDate }),
  });

  if (!response.ok) {
    throw new Error(`HTTP error! status: ${response.status}`);
  }

  return response.json();
}

export async function fetchTaskList(
  startDate: string,
  endDate: string
): Promise<TaskListType> {
const response = await fetch(`${host}/tasks`, {
  method: "POST",
  headers: {
    "Content-Type": "application/json",
    'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
  },
  body: JSON.stringify({ startDate, endDate }),
});

if (!response.ok) {
  throw new Error(`HTTP error! status: ${response.status}`);
}

return response.json();
}

export async function fetchStoreTaskList(
  startDate: string,
  endDate: string,
  bizplCd: string
): Promise<TaskListType> {
  const response = await fetch(`${host}/store/tasks`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({ startDate, endDate, bizplCd }),
  });

  return response.json();
}


export async function submitTaskInfo(
  taskType: string,
  targetDate: string,
  taskList: string[],
  startDate: string,
  endDate: string
): Promise<string> {
  const response = await fetch(`${host}/tasks/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({ taskType, targetDate, taskList, startDate, endDate }),
  });

  return response.status.toString();
}

// 임시
export async function submitStoreTasks(
  taskList: string[],
): Promise<string> {
  const response = await fetch(`${host}/store/task/submit`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({ taskList }),
  });

  return response.status.toString();
}


export async function updateTaskStatus(
  taskId: string,
  taskStatus: string,
): Promise<string> {
  const response = await fetch(`${host}/task/update`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      'Authorization': `Bearer ${sessionStorage.getItem("accessToken")}`,
    },
    body: JSON.stringify({ taskId, taskStatus }),
  });

  return response.status.toString();
}
