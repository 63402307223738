import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers';
import CalendarTaskList from './CalendarTaskList';

import { useSwipeable } from 'react-swipeable';
import { useEffect, useRef, useState } from 'react';
import Box from '@mui/material/Box/Box';
import Stack from '@mui/material/Stack/Stack';
import Button from '@mui/material/Button/Button';
import Typography from '@mui/material/Typography/Typography';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { useDispatch, useSelector } from 'react-redux';
import { setDateRange } from '../reducer/dateRange';
import 'dayjs/locale/ko';
import dayjs, { Dayjs } from 'dayjs';
import Divider from '@mui/material/Divider/Divider';
import { formatDate, formatDateControlTitle } from '../utils';


export default function Calendar() {
  const [isExpanded, setIsExpanded] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Dayjs>(dayjs());
  const [height, setHeight] = useState('0%');

  const tabIndex = useSelector((state: any) => state.tab);
  const dispatch = useDispatch();

  const calendarRef = useRef<HTMLDivElement>(null);

  const handlers = useSwipeable({
    onSwipedUp: () => setIsExpanded(true),
    onSwipedDown: (eventData) => {
      eventData.event.preventDefault();
      setIsExpanded(false);
    },
    preventScrollOnSwipe: true,
  });
  
  const handleDateChange = (newValue: Dayjs) => {
    console.log("## newValue ##", newValue);
    setSelectedDate(newValue);
  };

  const handleNextMonth = () => {
    setSelectedDate(selectedDate.add(1, 'month'));
  };

  const handlePrevMonth = () => {
    setSelectedDate(selectedDate.add(-1, 'month'));
  };

  useEffect(() => {
    if (!calendarRef.current) return;

    const resizeObserver = new ResizeObserver(entries => {
      for (let entry of entries) {
        const childNodes = Array.from(entry.target.children);
        // let childHeight = 505;
        let childHeight = 99+58;
        childNodes.forEach(child => {
          if (child instanceof HTMLElement) {
            childHeight = childHeight + child.offsetHeight;
          }
        });

        if (isExpanded) setHeight('calc(100dvh - 99px)');
        else setHeight(`calc(100dvh - ${childHeight}px)`);
      }
    });

    resizeObserver.observe(calendarRef.current);

    return () => resizeObserver.disconnect();
  }, [isExpanded]);

  useEffect(() => { 
    console.log("## selectedDate ##", formatDate('check!', selectedDate));
    dispatch(setDateRange({
      start_date: formatDate('selectedDate', selectedDate),
      end_date: formatDate('selectedDate', selectedDate),
      target_date: formatDate('selectedDate', selectedDate)
    }));
  }, [selectedDate, tabIndex]);

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="ko">
      <Stack direction={'column'} sx={{maxHeight: '100%', width: '100%'}} >
        <Stack direction={'row'} spacing={2} sx={{ py: 2, px: 1, justifyContent: 'center', alignItems: 'center', width: '100%' }}>
          <Button variant="outlined" color="info" size="small" className='btn-navigation'>
            <NavigateBeforeIcon className='btn-navigation-icon' onClick={handlePrevMonth} />
          </Button>
          <Typography sx={{ fontWeight: 700, color: '#424242', fontSize: '15px' }}>{formatDateControlTitle(selectedDate)}</Typography>
          <Button variant="outlined" color="info" size="small" className='btn-navigation'>
            <NavigateNextIcon className='btn-navigation-icon' onClick={handleNextMonth} />
          </Button>
        </Stack>
        <DateCalendar views={['day']} 
          showDaysOutsideCurrentMonth 
          ref={calendarRef} 
          value={selectedDate}
          onChange={handleDateChange}
          sx={{ minHeight: "348px", maxHeight: "348px"
          , '& .MuiPickersCalendarHeader-root': {display: "none"} 
          }}/>
        <Box sx={{
          flexGrow: 1,
          width: '100%',
          height: height,
          transition: 'height 0.3s ease',
          overflow: 'hidden',
          position: 'absolute',
          bgcolor: '#FFF',
          bottom: 0,
          left: 0,
          right: 0,
        }} >
          <Box {...handlers} sx={{ pt: 2, pb: 1, display: "flex", flexDirection: "column", boxShadow: 'inset 0 10px 10px -5px rgba(0,0,0,0.1)', gap: 1, alignItems: "center", width: '100%' }} >
            <Divider variant="middle" sx={{ height: "0px", width: "70px", border: "2px solid #616161", borderRadius: "2px" }} />
          </Box>
          <CalendarTaskList expanded={isExpanded}/>
        </Box>
      </Stack>
    </LocalizationProvider>
  );
}